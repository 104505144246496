var parallax = (function() {
  'use strict';

  var KOEF = 0.1;

  var elem;

  var parallax = {
    init: function(){
      window.addEventListener('scroll', moveBg);
    }
  };


  var moveBg = (function() {

    var timeWindow = 50; // time in ms
    var lastExecution = new Date((new Date()).getTime() - timeWindow);

    var moveBg = function() {
       // your code goes here
       document.body.style.backgroundPosition = '0 ' + (- document.body.scrollTop * KOEF) + 'px'
    };

    return function() {
      if ((lastExecution.getTime() + timeWindow) <= (new Date()).getTime()) {
        lastExecution = new Date();
        return moveBg.apply(this, arguments);
      }
    };
  }());


  return parallax;
}());

