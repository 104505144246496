var calltopapersForm = (function() {
  'use strict';
  var $form;

  var SUCCESS_TEXT = 'Ваша заявка принята!<br>Спасибо, мы скоро с вами свяжемся.';
  var ERROR_TEXT = 'Ошибка отправки!<br>Пожалуйста, повторите попытку.';

  // TODO:
  // - turn to false
  var USE_FAKE_FIELDS = false;

  var calltopapersForm = {
    init: function() {
      $form = $(document.forms.callforpaper);

      if (USE_FAKE_FIELDS) {
        fakeFillInput();
      }

      $form.on('submit', function(ev) {
        ev.preventDefault();
      });

      var instance = $form.parsley();

      instance.on('form:validate', function (formInstance) {
        $form.find('.js-form-responce').removeClass('mod-success mod-error');
        var ok = formInstance.isValid();
        if (ok) {
          calltopapersForm.sendRequest();
        }
      });

    },
    lock: function() {
      $form.find('.js-form-inp').attr('disabled', true);
    },
    unlock: function() {
      $form.find('.js-form-inp').attr('disabled', false);
    },
    sendRequest: function () {

      var formData = $form.serialize();
      calltopapersForm.lock();

      $.ajax({
          type: 'POST',
          url: 'http://services.jugru.org/callforpaper/',
          timeout: 20000,
          data: formData,
          success: function (data, status, xhr) {
            $form.find('.js-form-responce').html(SUCCESS_TEXT).addClass('mod-success');
            calltopapersForm.unlock();
          },
          error: function (xhr, status, error) {
            $form.find('.js-form-responce').html(SUCCESS_TEXT).addClass('mod-success');
            calltopapersForm.unlock();
          }
      });
    }
  };

  function fakeFillInput() {
    $form.find('.js-form-inp').each(function(index, item) {
      item.value = (item.type === 'email') ? 'test@fake.com' : 'test';
    })
  }

  return calltopapersForm;
}());
