var subscribtionForm = (function() {
  'use strict';

  var subscribtionForm = {
    init: function() {
      var $forms = $('.js-subscription-form');
      $forms.parsley();
    }
  };

  return subscribtionForm;
}());
