// hey! no jquery
// IE 9- require classList polyfill
var ticketsTable = (function() {
  'use strict';

  var ticketsTable = {
    init: function(){
      var allTables = document.querySelectorAll('.js-tickets-table');
      for (var i = 0; i < allTables.length; i++) {
        ticketsTable.initTable(allTables[i]);
      };
    },
    initTable: function(table) {
      var now = new Date();
      var rowElems = table.querySelector('tbody').children;
      var rowsLength = rowElems.length;
      var rows = [];

      // collect rows data
      for (var i = 0, data; i < rowsLength; i++) {
        var data = _getRowData(rowElems[i]);
        if (data) {
          rows.push(data);
        }
      };

      for (var i = 0; i < rows.length; i++) {
        var diffFrom = now - rows[i].dateFrom;
        var diffTo = rows[i].dateTo - now;

        if (diffFrom >= 0 || diffTo > 0) {
          _setCurrentRow(rows, i);
          break;
        }
      };
    }
  };

  function _getRowData(row) {

    // get date data
    var dateFromArray = row.getAttribute('data-date-from').split('-');
    var dateToArray = row.getAttribute('data-date-to').split('-');

    if (!dateFromArray || !dateToArray) {
      return false;
    }

    dateFromArray.unshift(null);
    dateToArray.unshift(null);
    dateToArray[4] = dateToArray[4] || 23; // set hours
    dateToArray[5] = dateToArray[5] || 59; // set minutes
    dateToArray[6] = dateToArray[6] || 59; // set seconds
    dateToArray[7] = dateToArray[7] || 999; // set ms

    // get dates
    var dateFrom = new (Function.prototype.bind.apply(Date, dateFromArray));
    var dateTo = new (Function.prototype.bind.apply(Date, dateToArray));

    return {
      row: row,
      dateFrom: dateFrom,
      dateTo: dateTo
    }
  }

  function _setCurrentRow(rows, index) {
    for (var i = 0; i < index; i++) {
      rows[i].row.classList.remove('mod-current');
      rows[i].row.classList.add('mod-past');
    };
    rows[index].row.classList.add('mod-current');

    if (rows[index + 1]) {
      var diff = Math.max(rows[index + 1].dateFrom - Date.now(), 0);
      setTimeout(function() {
        _setCurrentRow(rows, index + 1);
      }, diff);
    }
  }

  return ticketsTable;
}());
