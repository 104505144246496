var headerNav = (function() {
  'use strict';

  var SLIDING_DURATION = 200;
  var BREAKOPINT_MOBILE = 699;


  var $nav;
  var htmlElem;
  var isAnimating = false;
  var isMobile = false;

  var headerNav = {
    init: function() {
      $nav = $('#js-header-nav');
      htmlElem = document.documentElement;

      $(document).on('click', '.js-header-toggle', headerNav.toggleNav);

      $nav.on('click', function(ev) {
        ev.stopPropagation();
      })

      $(document).on('click', function() {
        if (htmlElem.classList.contains('mod-header-opened')) {
          headerNav.toggleNav();
        }
      });

      $(window).on('resize', onLoadResize);
      onLoadResize();
    },
    toggleNav: function (ev) {
      if (ev) {
        ev.preventDefault();
        ev.stopPropagation();
      }
      if (isAnimating || !isMobile) {
        return;
      }
      isAnimating = true;
      if (htmlElem.classList.contains('mod-header-opened')) {
        htmlElem.classList.remove('mod-header-opened');
        $nav.slideUp(SLIDING_DURATION, function() {
          isAnimating = false;
          $nav[0].classList.remove('mod-opened');
        });
      } else {
        $nav.slideDown(SLIDING_DURATION, function() {
          htmlElem.classList.add('mod-header-opened');
          isAnimating = false;
          $nav[0].classList.add('mod-opened');
        })
      }
    }
  };

  function onLoadResize() {
    if (window.innerWidth > BREAKOPINT_MOBILE) {
      if (isMobile) {
        $nav.removeAttr('style');
      }
      isMobile = false;
    } else {
      if (!isMobile) {
        $nav.removeAttr('style');
      }
      isMobile = true;
    }
  }

  return headerNav;
}());
