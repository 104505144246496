var galleryHandler = (function() {
  'use strict';

  var $gallery;
  var $slider;
  var $content;

  var ITEMS_DEFAULT = 5;
  var ITEMS_LAPTOP = 3;
  var BREAKPOINT_LAPTOP = 959;
  var ITEMS_MOBILE = 3;
  var BREAKPOINT_MOBILE = 399;
  var itemCount;
  var isMobile;
  var curMobile;
  var sliderInstance;
  var RESIZE_THROTTLE = 100;

  var galleryHandler = {
    init: function () {
      var resizeTimer;
      $(window).on('resize', function() {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function() {
          _checkState();
          _reInit();
        }, RESIZE_THROTTLE);
      });
      _checkState();


      $gallery = $('#gallery');
      $gallery.removeClass('disabled');

      $slider = $('#gallerySlider');
      $content = $('#galleryContent');

      // enumerate and translate slides
      var $slides = $slider.children();

      for (var i = 0, $last; i < Math.floor(itemCount / 2); i++) {
        $last = $slider.children().last();
        $last.prependTo($slider);
      }

      $slider.children().each(function(index, el) {
        el.setAttribute('data-index', 1 + index);
      });

      _reInit();
    },
    handleCenter: function (el) {
      if (isMobile) {
        return;
      }

      var curIndex = el.getCurrentSlideCount();
      var total = el.getTotalSlideCount();
      $(el).children('.center').removeClass('center');
      $content.html('');

      var centerSlide = _getCenterSlideState(total, curIndex);
      var $slide = $(el).children('[data-index="'+ centerSlide +'"]');
      $slide.addClass('center');
      $slide.find('.js-slide-content').first().clone().appendTo($content);
      requestAnimationFrame(function() {
        $content.find('.js-slide-content').removeClass('invis');
      });
    },
  };

  function _checkState() {
    var wW = $(window).width();

    if (wW <= BREAKPOINT_LAPTOP) {
      if (wW <= BREAKPOINT_MOBILE) {
        itemCount = ITEMS_MOBILE;
        isMobile = true;
      } else {
        itemCount = ITEMS_LAPTOP;
        isMobile = false;
      }
    } else {
      itemCount = ITEMS_DEFAULT;
      isMobile = false;
    }
  }

  function _reInit() {
    if (curMobile !== isMobile) {
      sliderInstance && sliderInstance.destroy();
      setTimeout(function() {
        $slider = $('#gallerySlider');
        sliderInstance = $slider.lightSlider(options);
      }, 0);
    } else {
      curMobile = isMobile;
    }
  }

  function _getCenterSlideState(total, current) {
    if (isMobile) {
      return current;
    }
    var offset = Math.floor(itemCount / 2);
    return (current + offset) % total || total;
  }

  var options = {
    item: ITEMS_DEFAULT,
    autoWidth: false,
    slideMove: 1, // slidemove will be 1 if loop is true
    slideMargin: 0,

    addClass: '',
    mode: "slide",
    useCSS: true,
    cssEasing: 'ease', //'cubic-bezier(0.25, 0, 0.25, 1)',//
    easing: 'linear', //'for jquery animation',////

    speed: 200, //ms'
    auto: false,
    loop: true,
    slideEndAnimation: true,
    pause: 2000,

    keyPress: true,
    controls: true,
    prevHtml: '<svg class="svg-arrow-left"><use xlink:href="sprite.svg#arrow-left"></use></svg>',
    nextHtml: '<svg class="svg-arrow-right"><use xlink:href="sprite.svg#arrow-right"></use></svg>',
    // prevHtml: '<span>i--;</span>',
    // nextHtml: '<span>i++;</span>',

    rtl:false,
    adaptiveHeight:true,

    vertical:false,
    vThumbWidth:100,

    thumbItem:10,
    pager: false,
    gallery: false,
    galleryMargin: 5,
    thumbMargin: 5,
    currentPagerPosition: 'middle',

    enableTouch:true,
    enableDrag:true,
    freeMove:true,
    swipeThreshold: 40,

    responsive : [
      {
        breakpoint: BREAKPOINT_LAPTOP,
        settings: {
          item: ITEMS_LAPTOP
        }
      },
      {
        breakpoint: BREAKPOINT_MOBILE,
        settings: {
          item: ITEMS_MOBILE,
          vertical: true,
          verticalHeight: 300
        }
      }
    ],

    onBeforeStart: function (el) {},
    onSliderLoad: galleryHandler.handleCenter,
    onBeforeSlide: function (el) {},
    onAfterSlide: galleryHandler.handleCenter,
    onBeforeNextSlide: function (el) {},
    onBeforePrevSlide: function (el) {}
  }


  return galleryHandler;
}());
