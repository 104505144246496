var shedule = (function() {
  'use strict';
  var $shedule;


  var shedule = {
    init: function() {
      $('.text-en').hide();
      $('#sheduleRU').addClass('active');

      $('#sheduleEN').click(function() {

        $('#sheduleEN').addClass('active');
        $('#sheduleRU').removeClass('active');

        $('.text-lang').each(function() {
          $(this).html($(this).data('en'));
        });
      });
      $('#sheduleRU').click(function() {
        $('#sheduleRU').addClass('active');
        $('#sheduleEN').removeClass('active');

        $('.text-lang').each(function(el) {
          $(this).html($(this).data('ru'));
        });
      });
    }
  };

  return shedule;
}());
