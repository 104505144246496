var shuffleHandler = (function() {
  'use strict';
  var $grid;
  var $gridControls;
  var currentTags = [];
  var maxCurrentTags = 0;

  var shuffleHandler = {
    init: function(){
      $grid = $('#grid');
      $gridControls = $('#grid-controls');
      shuffleHandler.setAllTags();

      maxCurrentTags = currentTags.length;

      $grid.shuffle();

      $('[data-tag]').on('click', _onControlClick);
      $('[data-tag-all]').on('click', _onControlAllClick);
    },
    setAllTags: function() {
      $gridControls.find('[data-tag]').each(function(index, item) {
        var tag = $(item).data('tag');
        currentTags.push(tag);
        $(item).addClass('active');
      });

      currentTags = uniques(currentTags);
    },
    triggerTag: function (tag) {
      if (!tag) {
        return;
      }

      // reset class
      if (currentTags.length === maxCurrentTags) {
        shuffleHandler.resetToOne(tag);
      } else {
        if (currentTags.indexOf(tag) !== -1) {
          removeA(currentTags, tag);
          $gridControls.find('[data-tag="'+ tag +'"]').removeClass('active');
        } else {
          currentTags.push(tag);
          $gridControls.find('[data-tag="'+ tag +'"]').addClass('active');
        }

      }


      shuffleHandler.filter();
    },
    resetAll: function () {
      currentTags = [];
      $gridControls.find('[data-tag]').removeClass('active');
    },
    resetToOne: function (tag) {
      currentTags = [tag];
      $gridControls.find('[data-tag]').removeClass('active');
      $gridControls.find('[data-tag="'+ tag +'"]').addClass('active');
    },
    filter: function() {
      if (currentTags.length > 0) {
        $grid.shuffle('shuffle', function($el) {

          var flag = false;
          var elemTags = $el.data('groups');

          var i = currentTags.length - 1;

          while (!flag && i >= 0) {
            var j = elemTags.length - 1;

            while (!flag && j >= 0) {
              if (currentTags[i] === elemTags[j]) {
                flag = true;
              }
              j--;
            }
            i--;
          }

          return flag;
        });
      } else {
        shuffleHandler.setAllTags();
        $grid.shuffle( 'shuffle', 'all' );
      }
    }
  };


  /**
   * click Control handler
   * @param   {Event} ev
   * @private
   */
  function _onControlClick(ev) {
    ev.preventDefault();
    var tag = $(this).data('tag');
    if ($(this).closest('#grid-controls').length === 0) {
      shuffleHandler.resetAll();
    }
    shuffleHandler.triggerTag(tag);
  }

  /**
   * click Control handler
   * @param   {Event} ev
   * @private
   */
  function _onControlAllClick(ev) {
    ev.preventDefault();
    shuffleHandler.resetAll();
    shuffleHandler.filter();
  }

  // function itemPassesFilters(data, val) {
  //   return ($.inArray(val, data) !== -1) ? true : false;
  // }



  /**
   * remove set of items from array
   * var ary = ['three', 'seven', 'eleven'];
   * removeA(ary, 'seven');
   * returns ['three', 'eleven']
   * @param  {Array} arr
   * @return {Array}
   */
  function removeA(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax= arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
  }


  /**
   * reduce array to unique values
   * @param  {Array} arr
   * @return {Array}
   */
  function uniques(arr) {
    var a = [];
    for (var i=0, l=arr.length; i<l; i++)
        if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
            a.push(arr[i]);
    return a;
  }



  return shuffleHandler;
}());
