var mapHandler = (function() {
  'use strict';

  var map;
  var mapContainer;
  var mapElem;
  var coords;
  var bounds;
  var marker;

  var mapHandler = {
    init: function () {
      var btn = document.getElementById('mapControl');
      mapContainer = document.getElementById('map');
      mapElem = document.getElementById('gmap');
      coords = JSON.parse(mapContainer.getAttribute('data-coords'));


      if (!mapContainer && !mapElem && !coords) {
        return;
      }

      document.documentElement.classList.add('mod-map-shown');

      map = new google.maps.Map(document.getElementById('gmap'), {
        center: coords,
        zoom: 16
      });

      marker = new google.maps.Marker({
        position: coords,
        map: map,
        icon: 'images/map-marker.png'
      });



      if (btn && navigator && navigator.geolocation) {
        btn.classList.remove('hidden');
        btn.addEventListener('click', function(ev) {
          ev.preventDefault();
          bounds = new google.maps.LatLngBounds();
          bounds.extend(marker.getPosition())

          mapHandler.locate();
        });
      }
    },
    locate: function () {
      navigator.geolocation.getCurrentPosition(function(position) {
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;

        var userMarker = new google.maps.Marker({
          position: {
            lat: latitude,
            lng: longitude
          },
          map: map
        });

        bounds.extend(userMarker.getPosition())
        map.fitBounds(bounds);

        var directionsDisplay = new google.maps.DirectionsRenderer({
          map: map,
          suppressMarkers : true
        });


        // Set destination, origin and travel mode.
        var request = {
          destination: marker.getPosition(),
          origin: userMarker.getPosition(),
          travelMode: google.maps.TravelMode.TRANSIT
        };

        // Pass the directions request to the directions service.
        var directionsService = new google.maps.DirectionsService();
        directionsService.route(request, function(response, status) {
          if (status == google.maps.DirectionsStatus.OK) {
            // Display the route on the map.
            directionsDisplay.setDirections(response);
          }
        });
      });
    }
    // toggleBounce: function (marker) {
    //   return function () {
    //     if (marker.getAnimation() !== null) {
    //       marker.setAnimation(null);
    //     } else {
    //       marker.setAnimation(google.maps.Animation.BOUNCE);
    //     }
    //   }
    // }
  };



  return mapHandler;
}());
