initMap = mapHandler.init;

jQuery(document).ready(function($) {
  document.querySelector('.js-subscription-form') && subscribtionForm.init();
  document.forms.callforpaper && calltopapersForm.init();
  document.querySelector('.js-tickets-table') && ticketsTable.init();
  document.querySelector('#grid') && shuffleHandler.init();
  document.querySelector('#gallery') && galleryHandler.init();
  document.querySelector('.js-parallax') && parallax.init();
  document.querySelector('#js-header-nav') && headerNav.init();
  document.querySelector('#sheduleTable') && shedule.init();
});

